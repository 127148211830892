<template>
  <div class="material__shop__wrapper">
    <div class="commom_title">
      <a-row type="flex" justify="space-between">
        <a-col :span="8">
          <a-row>
            <a-col :span="2">
              <div class="commom_line"></div>
            </a-col>
            <a-col :span="6">物资商城</a-col>
          </a-row>
        </a-col>
        <!-- <a-col :span="2">
          <a-badge :count="buyCarNumber">
            <a-button @click="handleRouter('/supplies/supplies-car')" class="a-button">
              <a-icon type="shopping-cart" />购物车
            </a-button>
          </a-badge>
        </a-col> -->
      </a-row>
    </div>
    <div class="content">
      <div class="materialShop_search">
        <a-row type="flex" justify="space-between">
          <a-col :span="11">
            <a-input-search
              placeholder="商品关键字查询"
              v-model="queryParams.productName"
              @search="Search"
              enter-button="搜索"
            />
          </a-col>
          <a-col :span="11">
            <a-input-search
              placeholder="供应商关键字查询"
              v-model="queryParams.businessName"
              @search="Search"
              enter-button="搜索"
            />
          </a-col>
        </a-row>
      </div>
      <div class="materialShop_tab">
        <v-tab :tabArr="tabs" v-model="queryParams.type" @change="getTab" />
      </div>
      <div class="materialShop_choose">
        <a-row>
          <a-col :span="8">
            <div
              class="materialShop_chooseItem"
              @click="getSort('sort')"
              :class="[queryParams.sort === 'sort' ? 'active_materialShop_chooseItem' : '']"
            >
              综合排序
            </div>
          </a-col>
          <a-col :span="8">
            <div
              class="materialShop_chooseItem"
              :class="[queryParams.sort === 'salesVolume:desc' ? 'active_materialShop_chooseItem' : '']"
              @click="getSort('salesVolume:desc')"
            >
              销量优先
            </div>
          </a-col>
          <a-col :span="8">
            <div
              class="materialShop_chooseItem"
              :class="[queryParams.sort === 'price:asc' ? 'active_materialShop_chooseItem' : '']"
              @click="getSort('price:asc')"
            >
              价格优先
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="materialShop_content" style="height: 680px">
        <v-card-list
          ref="listCard"
          :getDataApi="getDataApi"
          :renderItem="renderItem"
          :colConfig="{
            span: 4,
          }"
        >
          <a-empty slot="empty" :description="emptyText" />
        </v-card-list>
      </div>
    </div>
  </div>
</template>

<script>
import { page, employmentMallList } from '@/api/materialShop'

const tabs = [
  {
    label: '全部',
    value: null
  },
  {
    label: '劳保',
    value: '1'
  },
  {
    label: '工具',
    value: '2'
  },
  {
    label: '备件',
    value: '3'
  },
  {
    label: '材料',
    value: '4'
  },
  {
    label: '办公用品',
    value: '6'
  },
  {
    label: '源头好品',
    value: '7'
  },
  {
    label: '二手物资',
    value: '5'
  }
]

export default {
  name: 'Index',
  inject: ['reload'],
  data () {
    return {
      queryParams: {
        sort: 'sort',
        productName: '',
        type: null
      },
      tabs,
      buyCarNumber: 0,
      emptyText: '暂未找到您搜索的商品'
    }
  },
  created () {
    this.queryParams.productName = this.$route.query.name || ''
    this.handleGetBuyNumber()
  },
  methods: {
    handleGetBuyNumber () {
      if (this.isLogin) {
        employmentMallList().then((res) => {
          this.buyCarNumber = res.data.reduce((number, item) => {
            return (number += item.products.length)
          }, 0)
        })
      }
    },
    handleRouter (path) {
      if (this.handleIsLogin()) {
        this.$router.push(path)
      }
    },
    getSort (val) {
      this.queryParams.sort = val
      this.$refs.listCard.pageInit()
    },
    getTab (val) {
      this.queryParams.type = val
      this.$refs.listCard.pageInit()
    },
    Search () {
      this.$refs.listCard.pageInit()
    },
    getDetail (item) {
      window.open(`/supplies/supplies-details?id=${item.productId}`)
    },
    getDataApi (params) {
      this.emptyText = '努力搜索中...'
      return page({
        ...params,
        ...this.queryParams,
        status: 1
      }).then((res) => {
        // eslint-disable-next-line promise/param-names
        return new Promise((reslove) => {
          this.emptyText = (res.data && res.data.length === 0) ? '暂未找到您搜索的商品' : '努力搜索中...'
          reslove({
            data: res.data,
            pageIndex: res.pageIndex,
            pageSize: res.pageSize,
            totalCount: res.totalCount
          })
        })
      }).catch(() => {
        this.emptyText = '哎呀，出错了，请稍后再试！'
      })
    },
    renderItem ({ item, index, data }) {
      return (
        <div class="materialShop_content_item" onClick={this.getDetail.bind(null, item)}>
          <div class="materialShop_content_image">
            <img src={item.pic} alt="" />
            <div class="materialShop_content_name text-overflow" title={item.productName}>
              {item.productName}
            </div>
            <div class="materialShop_content_text text-overflow">品牌：{item.brand}</div>
            <div class="materialShop_content_text">
              <a-row>
                <a-col span={12}>
                  <span class="text-overflow">单位：{item.unit}</span>
                </a-col>
                <a-col span={12}>
                  <span class="text-overflow" style="color: #FF1F1F">
                    ￥{item.price}
                  </span>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      )
    }
  }
}
</script>
<style lang="less">
@import url('../../../common/style');
@import url('./index');
.material__shop__wrapper {
  .v--tab__wrapper {
    height: 100%;
    display: flex;

    .v--tab--item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .v--card--list {
    .ant-col-4 {
      width: 20% !important;
    }
  }
}
</style>
<style lang="less" scoped>
.materialShop_content_image {
  margin: 0 auto;
}
//购物车样式
.a-button {
  color: #1890ff;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
}
</style>
